import http from "../axios-instance";

class RegYearService {
  createVYears(year) {
    return http
      .post("/reg-search/years", year)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getVYears(params) {
    return http
      .get("/reg-search/years", { params: params })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  updateVYears(year) {
    return http
      .put(`/reg-search/years/${year._id}`, year)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  deleteVYears(year) {
    return http
      .delete(`/reg-search/years/${year}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getSelected(params) {
    return http
      .get(`/reg-search/years/selected/${params?.vyear ?? ""}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}

export default new RegYearService();
