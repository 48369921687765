import http from "../axios-instance";

class RegModelService {
  createVModel(model) {
    return http
      .post("/reg-search/models", model)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getVModels(params) {
    return http
      .get("/reg-search/models", { params: params })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  updateVModel(model) {
    return http
      .put(`/reg-search/models/${model._id}`, model)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  deleteVModel(model) {
    return http
      .delete(`/reg-search/models/${model._id}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getSelected(params) {
    return http
      .get(`/reg-search/models/selected/${params?.vmodel ?? ""}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}

export default new RegModelService();
