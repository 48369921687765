import http from "../axios-instance";

class RegFinalDataService {
  createVPower(power) {
    return http
      .post("/reg-search/vehicle-finals", power)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getFinalData(params) {
    return http
      .get("/reg-search/vehicle-finals", { params: params })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  updateDetails(id, data) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return http
      .put(`/reg-search/vehicle-finals/${id}`, data, config)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  deleteVPower(power) {
    return http
      .delete(`/reg-search/vehicle-finals/${power._id}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

}

export default new RegFinalDataService();
