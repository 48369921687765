import http from "../axios-instance";

class RegFuelService {
  createVFuels(fuel) {
    return http
      .post("/reg-search/fuel-types", fuel)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getVFuels(params) {
    return http
      .get("/reg-search/fuel-types", { params: params })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  updateVFuel(fuel) {
    return http
      .put(`/reg-search/fuel-types/${fuel._id}`, fuel)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  deleteVFuel(fuel) {
    return http
      .delete(`/reg-search/fuel-types/${fuel._id}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getSelected(params) {
    return http
      .get(`/reg-search/fuel-types/selected/${params?.vfuel ?? ""}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}

export default new RegFuelService();
