import http from "../axios-instance";

class RegPowerService {
  createVPower(power) {
    return http
      .post("/reg-search/powers", power)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getVPowers(params) {
    return http
      .get("/reg-search/powers", { params: params })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  updateVPower(power) {
    return http
      .put(`/reg-search/powers/${power._id}`, power)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  deleteVPower(power) {
    return http
      .delete(`/reg-search/powers/${power._id}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

}

export default new RegPowerService();
