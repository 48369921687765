import http from "../axios-instance";

class RegEngineService {
  createVEngine(engine) {
    return http
      .post("/reg-search/engine-type", engine)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getVEngines(params) {
    return http
      .get("/reg-search/engine-type", { params: params })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  updateVEngine(engine) {
    return http
      .put(`/reg-search/engine-type/${engine._id}`, engine)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  deleteVEngine(engine) {
    return http
      .delete(`/reg-search/engine-type/${engine._id}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getSelected(params) {
    return http
      .get(`/reg-search/engine-type/selected/${params?.vengine ?? ""}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}

export default new RegEngineService();
