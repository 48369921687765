import http from "../axios-instance";

class RegMakeService {
  createVMake(vMake) {
    return http
      .post("/reg-search/vmake", vMake)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  updateVMake(vMake) {
    return http
      .put(`/reg-search/vmake/${vMake._id}`, { vMake })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  getVMakes(params) {
    return http
      .get("/reg-search/vmake", { params: params })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  deleteVMake(vMakeId) {
    return http
      .delete(`/reg-search/vmake/${vMakeId}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getSelected(params) {
    return http
      .get(`/reg-search/makes/selected/${params?.vmake ?? ""}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getSelectedAll() {
    return http
      .get(`/access/reg-search/makes/selected`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}




export default new RegMakeService();
